
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box
}

body,
html {
    height: 100%;
    font-family: 'Arial';
}

a {
    font-size: 14px;
    line-height: 1.7;
    color: #666;
    margin: 0;
    transition: all .4s;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -moz-transition: all .4s
}

a:focus {
    outline: none !important
}

a:hover {
    text-decoration: none;
    color: #111
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0
}

p {
    font-size: 14px;
    line-height: 1.7;
    margin: 0
}

ul,
li {
    margin: 0;
    list-style-type: none
}

input {
    outline: none;
    border: none
}

input[type=number] {
    -moz-appearance: textfield;
    appearance: none;
    -webkit-appearance: none
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none
}

textarea {
    outline: none;
    border: none
}

textarea:focus,
input:focus {
    border-color: transparent !important
}

input::-webkit-input-placeholder {
    color: #CCC
}

input:-moz-placeholder {
    color: #CCC
}

input::-moz-placeholder {
    color: #CCC
}

input:-ms-input-placeholder {
    color: #CCC
}

textarea::-webkit-input-placeholder {
    color: #CCC
}

textarea:-moz-placeholder {
    color: #CCC
}

textarea::-moz-placeholder {
    color: #CCC
}

textarea:-ms-input-placeholder {
    color: #CCC
}

button {
    outline: none !important;
    border: none;
    background: 0 0
}

button:hover {
    cursor: pointer
}

iframe {
    border: none !important
}

.txt1 {
    font-size: 20px;
    color: #333;
    line-height: 1.2
}

.txt2 {
    font-size: 16px;
    color: #999;
    line-height: 1.4
}

.txt3 {
    font-size: 16px;
    color: #333;
    line-height: 1.2;
    text-transform: uppercase
}

.bg1 {
    background-color: #3b5998
}

.bg2 {
    background-color: #1da1f2
}

.bo1 {
    border-bottom: 1px solid #929292
}

.hov1:hover {
    border-bottom: 1px solid #111;
    color: #111
}

.limiter {
    width: 100%;
    margin: 0 auto
}

.container-login100 {
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    position: relative;
    background-color: #e6e6e6
}

.wrap-login100 {
    width: 320px;
    background: 0 0;
    border-radius: 0
}

.login100-form {
    width: 100%;
    position: relative
}

.login100-form-title {
    font-size: 30px;
    color: #43383e;
    line-height: 1.2;
    text-align: center;
    display: block
}

.btn-login-with {
    font-size: 16px;
    color: #fff;
    line-height: 1.2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    border-radius: 25px
}

.btn-login-with i {
    font-size: 20px;
    margin-right: 10px
}

.btn-login-with:hover {
    color: #fff;
    background-color: #333
}

.wrap-input100 {
    width: 100%;
    background-color: #fff;
    border-radius: 31px;
    position: relative;
    z-index: 1
}

.input100 {
    font-size: 16px;
    color: #43383e;
    line-height: 1.2;
    position: relative;
    display: block;
    width: 100%;
    height: 50px;
    background: #fff;
    border-radius: 25px;
    padding: 0 25px
}

.focus-input100 {
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    border-radius: 31px;
    background-color: #fff;
    pointer-events: none;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -moz-transition: all .4s;
    transition: all .4s
}

.input100:focus+.focus-input100 {
    width: calc(100% + 20px)
}

.btn-show-pass {
    font-size: 15px;
    color: #aaa;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 200;
    height: 100%;
    top: 0;
    right: 25px;
    padding: 0 5px;
    cursor: pointer;
    -webkit-transition: background .4s;
    -o-transition: background .4s;
    -moz-transition: background .4s;
    transition: background .4s
}

.btn-show-pass:hover {
    color: #111
}

.btn-show-pass.active {
    color: #111
}

.container-login100-form-btn {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center
}

.login100-form-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    min-width: 160px;
    height: 50px;
    background-color: transparent;
    border-radius: 31px;
    font-size: 16px;
    color: #fff;
    line-height: 1.2;
    text-transform: uppercase;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -moz-transition: all .4s;
    transition: all .4s;
    position: relative;
    z-index: 1
}

.login100-form-btn::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    border-radius: 31px;
    background-color: #333;
    pointer-events: none;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -moz-transition: all .4s;
    transition: all .4s
}

.login100-form-btn:hover:before {
    background-color: #222;
    width: calc(100% + 20px)
}

.alert-validate .btn-show-pass {
    visibility: hidden
}

.validate-input {
    position: relative
}

.alert-validate::before {
    content: attr(data-validate);
    position: absolute;
    z-index: 1000;
    max-width: 70%;
    background-color: #fff;
    border: 1px solid #c80000;
    border-radius: 14px;
    padding: 4px 25px 4px 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 10px;
    pointer-events: none;
    color: #c80000;
    font-size: 13px;
    line-height: 1.4;
    text-align: left;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity .4s;
    -o-transition: opacity .4s;
    -moz-transition: opacity .4s;
    transition: opacity .4s
}

.alert-validate::after {
    content: "\f06a";
    font-family: FontAwesome;
    display: block;
    position: absolute;
    z-index: 1100;
    color: #c80000;
    font-size: 16px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 16px
}

.alert-validate:hover:before {
    visibility: visible;
    opacity: 1
}

@media(max-width:992px) {
    .alert-validate::before {
        visibility: visible;
        opacity: 1
    }
}

@media(max-width:576px) {
    .wrap-login100 {
        padding-top: 20px
    }
}