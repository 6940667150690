.layout-login {
    width: 100%;
    height: 100vh;
    background: linear-gradient(#183861, #08B1C0);
}

.btn-login-gr {
    padding-top: 100px;
}

.header-logo img {
    max-width: 70px;
}

.header-menu-one {
    background: linear-gradient(#183861, #08B1C0);
}

.navbar-expand-md .navbar-collapse {
    justify-content: end;
}

.admin-img img {
    width: 40px;
    border-radius: 100%;
}

.navbar {
    height: 60px;
}

.header-main-menu .navbar-nav .header-admin .dropdown-menu .item-content .settings-list li a {
    text-decoration: none;
    cursor: pointer;
}

.dashboard-content-one {
    max-height: calc(100vh - 60px);
}

.react-datetime-picker .react-datetime-picker__wrapper {
    border: none;
}

.react-datetime-picker .react-datetime-picker__button {
    padding: 0 5px;
}

.react-datetime-picker .react-datetime-picker__calendar {
    z-index: 99;
}

.header-main-menu .navbar-nav .header-admin .navbar-nav-link {
    text-decoration: none;
}

.navbar .header-main-menu .navbar-nav .header-admin .navbar-nav-link .admin-title .item-title {
    color: #FFF;
    text-decoration: none;
}

.navbar .header-main-menu .navbar-nav .header-admin .navbar-nav-link .admin-title span {
    color: #e5e5e5;
}

.home-dashboad .form-info {

    padding: 15px;
}

.bg-white-border {
    background-color: #FFF;
    border-radius: 5px;
}

.pd-15 {
    padding: 15px;
}

.sidebar-main {
    height: calc(100vh - 60px);
    transition: all .5s;
}

.wrapper a {
    text-decoration: none;
}

.wrapper .breadcrumbs-area {
    padding-top: 15px;
    padding-bottom: 15px;
}

.wrapper .breadcrumbs-area ul {
    margin: 0;
    padding-left: 0;
}

ul.pagination {
    justify-content: center;
}

.search-box {
    position: relative;

}

.search-box .list-result {
    position: absolute;
    top: 32px;
    border: 1px solid #e5e5e5;
    border-radius: 3px;
    max-height: 250px;
    overflow-y: auto;
    background-color: #FFF;
    padding: 0;
    width: 100%;
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
    z-index: 99;
}

.search-box .list-result ul {
    margin: 0;
    padding: 0;
    width: 100%;
}

.search-box .list-result ul li {
    cursor: pointer;
    border-bottom: 1px solid #eee;
    width: 100%;
    padding: 10px 15px;
    border: none;
    border-radius: 0;
    background: transparent;
    text-align: left;
    transition: all 100ms linear;
}

.search-box .list-result ul li:hover {
    border-color: #2fcc8b;
    background-color: #2fcc8b;
    color: #FFF
}

.info-user-mobile {
    display: none;
}

@media only screen and (max-width: 767px) {
    .hidden-xs.navbar-nav {
        display: none;
        ;
    }

    .nav-bar-header-one .header-logo a {
        text-align: left;
        padding-left: 15px;
    }

    .mobile-nav-bar .navbar-toggler .fas {
        color: #FFF;
    }

    .navbar-expand-md .navbar-collapse {
        z-index: 99;
        background: linear-gradient(#08B1C0, #183861);
        margin-top: 8px;
        padding: 0;

    }

    .navbar-expand-md .navbar-collapse .settings-list {
        padding: 0;
        margin: 0;
    }

    .navbar-expand-md .navbar-collapse .settings-list li {
        width: 100%;
        border-top: 1px solid #eee;
    }

    .navbar-expand-md .navbar-collapse .settings-list li a {
        width: 100%;
        padding: 10px 15px;
        display: inline-block;
        color: #FFF;
    }

    .navbar-expand-md .navbar-collapse .settings-list li a i {
        margin-right: 10px;
    }

    .navbar-expand-md .navbar-collapse .settings-list li a i:before {
        margin-left: 0;
    }

    .u-info .admin-title,
    .u-info .item-title {
        color: #FFF;
        margin: 0;
    }

    .u-info {
        padding: 10px 15px;
        align-items: center;
    }

    .u-info .admin-title {
        padding-left: 15px;
    }

    .mobile-nav-bar .navbar-toggler {
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
    }
}

.notification {
    position: fixed;
    z-index: 999;
    bottom: 35px;
    right: 35px;
}

.item-notification .alert {
    padding: 10px 35px 10px 10px;
    width: 200px;
}

.item-notification .alert .close {
    padding: 8px;
}

.list-search-box ul.list-data {
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #e5e5e5;
}

.list-search-box ul.list-data li {
    padding: 5px 10px;
    border-top: 1px solid #e5e5e5;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    cursor: pointer;
}

.list-search-box .pagination {
    margin-top: 20px;
    margin-bottom: 0;
}

.card-filter .title {
    margin: 0;
}

.card-body {
    transition: all .5s;
}

.hidden-filter {
    height: 0;
    flex: 1 1 auto;
    padding: 0 1rem;
    overflow: hidden;
    transition: all .5s;
}

.table-cham-cong {
    width: 100%;
    margin-bottom: 15px;
    font-size: 13px;
    overflow-x: scroll;
    position: relative;
    display: inline-block;
    overflow-y: hidden;
}

.table-cham-cong.table-chotcong .table-tr .item-td {
    min-width: 100px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.table-cham-cong.table-chotcong .table-tr .item-td.td-action {
    min-width: 350px;
}

.table-cham-cong .wraper-table {
    width: max-content;
    height: calc(100vh - 300px);
}

.table-cham-cong .table-body {
    overflow-y: scroll;
    height: calc(100vh - 300px);
    position: relative;
    padding-top: 60px;
    padding-bottom: 10px;
}
.table-cham-cong.table-chotcong .table-body {
    padding-top: 50px;
    border: 1px solid #e5e5e5;
}
.table-cham-cong .table-header {
    z-index: 999;
    background-color: #042954;
    color: #FFF;
    position: absolute;
    left: 0;
    top: 0;
}

.table-cham-cong .table-tr {
    display: flex;
    border: 1px solid #e5e5e5;

}

.table-cham-cong .table-tr .item-td {
    width: auto;
    padding: 5px;
    border-right: 1px solid #e5e5e5;
}

.table-cham-cong .table-tr .item-td.td-day {
    width: 50px;
    text-align: center;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-cham-cong .table-tr .item-td.td-id {
    width: 35px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-cham-cong .table-tr .item-td.td-fullname {
    width: 160px;
    min-width: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-cham-cong .table-tr .item-td.td-code {
    width: 100px;
    min-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-cham-cong .table-tr .item-td.head-day {
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-cham-cong .table-tr .item-td.td-part,
.table-cham-cong .table-tr .item-td.td-company {
    width: 160px;
    min-width: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-cham-cong .table-tr .item-td.td-day-8 {
    background: #d7d7d7;
}

.table-cham-cong .table-body .table-tr:nth-child(2n+1) {
    background-color: #ededed;
}

.table-cham-cong .table-body .table-tr:hover,
.table-cham-cong .table-body .table-tr.tr-active {
    background: #d7d7d7;
}



.font-13 {
    font-size: 13px;
}

.modal .modal-dialog .modal-content .body-image-box {
    padding: 0;
}

.image-box-wrapper {
    padding: 0;
    margin: 0;
}

.search-image {
    margin: 7px 0;
}

.image-box-wrapper .list-image {
    padding: 0;
    margin: 0;
    min-height: 300px;
}

.image-box-wrapper .list-image .item-image {
    margin: 7px 0;
    padding: 0 7px;

    transition: all .3s;
}

.body-image-box .pagination-group {
    margin-top: 7px;
}

.body-image-box .image-box-files {
    padding: 0;
}

.header-image-wrapper {
    border-bottom: 1px solid #e5e5e5;
}

.content-img-box .content-wrapper {
    position: relative;
}

.content-img-box .itemcheck {
    display: none;
    color: #FFF;
    border-radius: 100%;
    position: absolute;
    top: 5px;
    left: 5px;
    background-color: #042954;
}

.content-img-box {
    padding: 5px;
    border: 1px solid;
    border-color: #e5e5e5;
    border-radius: 5px;
    display: inline-block;
    width: 100%;
    transition: all .3s;
    cursor: pointer;
}

.content-img-box:hover,
.list-image .active .content-img-box {
    background-color: #e5e5e5;
}

.list-image .active .itemcheck {
    display: block;
}

.img-wrapper {
    border-radius: 5px;
    overflow: hidden;
}

.image-box-wrapper .list-image .item-image img {
    min-width: 100%;
    height: 130px;
    object-fit: cover;
}

.image-box-wrapper .list-image .item-image .filename {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-top: 5px;
    height: 40px;
    font-size: 13px;
    width: 100%;
    display: inline-block;
    float: left;
}

.image-box-wrapper .list-image .item-image .date {
    font-size: 11px;
    text-align: right;
    width: 100%;
    display: inline-block;
    float: left;
    margin-top: 5px;
}

.image-box-wrapper .list-image .breadcrumb {
    background: transparent;
    padding: 10px 15px;
    margin: 0;
}

.image-box-wrapper .list-image .breadcrumb .item {
    color: #042954;
    font-size: 12px;
    margin-left: 5px;
}

.upload-group .btn {
    outline: none;
    box-shadow: none;
}

.form-select.height32 {
    font-size: 13px;
    background-color: #f0f1f3;
}

.img-customizer {
    width: 100px;
    height: 150px;
    object-fit: cover;
    margin-bottom: 5px;
}

.select-multiple {
    font-size: 12px !important;
}

.dashboard-list-new {
    padding: 0;
    margin: 0;
}

.dashboard-list-new a {

    color: #212529;
}

.dashboard-list-new a:hover {
    color: #dc3545;
}
.file-dinh-kem a{
    padding: 0;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    overflow: hidden;
}
.file-dinh-kem a span {
    position: absolute;
    right: 0;
    top: 0;
    padding: 5px;
    background-color: #FFF;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
}
.file-dinh-kem a p {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    padding: 5px;
}
.file-dinh-kem a img{
    width: 100%;
    height: 120px;
    object-fit: cover;
    
}
.file-dinh-kem-2 .item-image{
    padding: 10px;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
}
.file-dinh-kem-2 a img{

    width:auto;
    max-width: 50px;
}
.file-dinh-kem-2 a span {
    position: relative;
}
.file-dinh-kem-2 a {
    color: #212529;
}
.sidebar-menu-one .sidebar-menu-content {
    overflow-y: auto;
    max-height: calc( 100vh - 80px);
}
.sidebar-menu-one .sidebar-menu-content .nav-sidebar-menu .nav-item.active .nav-link {
    position: relative;
}
.sidebar-menu-one .sidebar-menu-content .nav-sidebar-menu > .nav-item > .nav-link span.sub-sp,
.sidebar-menu-one .sidebar-menu-content .sub-group-menu .nav-link span.sub-sp {
    position: absolute;
    top: 5px;
    font-size: 8px;
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 10px;
    background-color: #dc3545;
    text-align: center;
    line-height: 15px;
    font-weight: 700;
}
.sidebar-menu-one .sidebar-menu-content .sub-group-menu .nav-link span.sub-sp{
    right: 0;
}

.table-cham-cong .wraper-table .item-stt{
    width: 40px;
    min-width: 40px;
}

.table-cham-cong .wraper-table .item-title {
    width: 220px;
    min-width: 220px;
}
.table-cham-cong .wraper-table .item-kiennghi,
.table-cham-cong .wraper-table .item-note,
.table-cham-cong .wraper-table .item-desc{
    width: 270px;
    min-width: 270px;
    overflow: hidden;
}
.table-cham-cong .wraper-table .item-trienkhai,
.table-cham-cong .wraper-table .item-thamgia,
.table-cham-cong .wraper-table .item-quansat,
.table-cham-cong .wraper-table .item-author {
    min-width: 150px;
    width: 150px;
}
.table-cham-cong .wraper-table .item-status,
.table-cham-cong .wraper-table .item-datecreate,
.table-cham-cong .wraper-table .item-deadline,
.table-cham-cong .wraper-table .item-dateend,
.table-cham-cong .wraper-table .item-datestart{
    width: 100px;
    min-width: 100px;
}
.listCV .table-cham-cong .table-tr .item-th {
    min-height: 50px;
}
.item-note textarea,
.item-kiennghi textarea{
    min-height: 100px;
}

.listCV .table-cham-cong .table-tr .item-th p {
    width: 100%;
    font-size: 13px;
}
.listCV .table-cham-cong .table-tr a {
    color: #042954;
    font-weight: 600;
}
.pseudo-bg-info::after {
    content: '';
    background-color: #0dcaf0;
}
.pseudo-bg-orange::after {
    content: '';
    background-color: #fd7e14;
}
.text-xdanger {
    color: #fd7e14 !important;
}
.text-total {
    color: #183861 !important;
}
.dashboard-card-three .card-body .student-report .student-count:after {
    left: 15px;
}
.pseudo-bg-success::after {
    content: '';
    background-color: #28a745;
} 
.dashboard-card-three .card-body .student-report:before{
    display: none;
}

.student-count .line{
    height: 7px;
    border-radius: 5px;
    display: inline-block;
    position: absolute;
}
.bg-total {
    height: 7px;
    border-radius: 5px;
    display: inline-block;
    position: relative;
    overflow: hidden;
    background-color: #dddddd;
}
.student-count .line.line-total{
    width: 100%;
    background-image: linear-gradient(to right, #183861, #08B1C0);
}
.student-count .line.line-yellow{
    background-color: #ffc107;
}
.student-count .line.line-info{
    background-color: #0dcaf0;
}
.student-count .line.line-success{
    background-color: #198754;
}
.student-count .line.line-danger{
    background-color: #dc3545;
}
.student-count .line.line-xdanger{
    background-color: #fd7e14;
}

.table-cham-cong .wraper-table .table-body .item-status {
    padding: 0;
    color: #FFF;
}
.item-status span {
    display: inline-block;
    width: 100%;
    height: 100%;
    padding: 5px;
}
.dashboard-card-three .card-body .student-report .student-count .item-number {
    font-size: 24px;
}
.wrapper .sidebar-menu-one .sidebar-menu-content .nav-sidebar-menu > .nav-item .sub-group-menu > .nav-item .nav-link {
    padding-left: 30px;
}
@media only screen and (max-width: 767px) {
    .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center, 
    .apexcharts-legend.apx-legend-position-top.apexcharts-align-center{
        max-width: 100%;
    }
    .dashboard-card-three .card-body .student-report .student-count{
        margin-top: 15px;
    }
}